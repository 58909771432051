import React, { useEffect, useState } from "react";
import { Link } from "@StarberryUtils"
import { useStaticQuery, graphql } from "gatsby"
import { Row, Col, Container } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import _ from "lodash"
import Select from "react-select"
// import $ from "jquery";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import logoBlack from "../../images/redbrik-new-logo.svg";
import { isTablet, isMobile, isMobileOnly, isDesktop } from 'react-device-detect';
import { LATEST_NEWS_PAGE_URL, MEET_THE_TEAM_PAGE_URL } from "../common/site/constants";

import MailForm from "./team-landing-form"

import "../StaticPage/AboutPage/AboutPage.scss";
import "../StaticPage/News/News.scss"; 
 

function TeamList(props) {
  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      peoples(sort: "Sort:asc", where: { Publish: true }) {
        Name
        URL
        id
        Designation
        Email
        Image {
          url
        }
        Choose_Category {
          URL
        }
        imagetransforms
      }

      peopleCategories(sort: "Sort:asc", where: { Publish: true }) {
        Name
        URL
      }
    }
  }
`)

    const [filteredTeam, setFilteredTeam] = useState(data.glstrapi?.peoples)
    const [allcategory, setAllCategory] = useState(data.glstrapi?.peopleCategories)
    const [screenSize, setScreenSize] = useState(0);
    const lettingsPropList = [`Director`, `Associate Director`, `Managing Director`]
    const [allTeam, setAllTeam] = useState(data.glstrapi?.peoples)
    const [category, setCategory] = useState('directors')

 

    const customStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isFocused ? "#B20838" : null,
            color: isFocused ? "#ffffff" : "#575757",
          }
        }, 
        dropdownIndicator: styles => ({
          ...styles,
          //  color: "#ffffff",
        }),
        indicatorsContainer: styles => ({
          ...styles,
          // color: '#ffffff',
          padding: 0,
          cursor: "pointer",
        }),
        indicatorSeparator: () => null
      } 

    const filteredList = () => {

        var teams = [];
        if (category == 'all') {
            teams = allTeam
        }
        else {
            teams = allTeam.filter(team => {
                let category_1 = _.includes(_.map(team.Choose_Category, 'URL'), category)
                if (category_1) {
                    return category_1
                }
            });
        }
        setFilteredTeam(teams)

    }

    useEffect(() => {
        filteredList()
        // setFilteredTeam(teams)
        if (props.Select_Category_here) {
            setCategory(props.Select_Category_here.URL)
        }
      

    }, [category]);
    useEffect(() => {
        deviceWidth()
        window.addEventListener("resize", () => {
            deviceWidth()
        });
    })

    const deviceWidth = () => {
        if (window) {
            setScreenSize(window.innerWidth)
        }
    }

    // const temp = filteredTeam;

    // var temp = []

    // chunks.map((person,key) => {
    //     temp.push(_.take(person, 5))
    //     temp.push(_.slice(person, 5, person.length))
    // })

    // console.log("teams", temp)
    const ImageRender = ({ image_url, node, processedImages }) => {
        return (
            <ImageTransform imagesources={image_url} renderer="srcSet" imagename="peoples.Image.tile" attr={{ alt: node.Name + ' - Redbrik', class: '' }} imagetransformresult={processedImages} id={node.id} />
        )
    }
    const ImageRenderOne = ({ image_url, node, processedImages }) => {
        return (
            <ImageTransform imagesources={image_url} renderer="srcSet" imagename="peoples.Image.tile_1" attr={{ alt: node.Name + ' - Redbrik', class: '' }} imagetransformresult={processedImages} id={node.id} />
        )
    }
    const ImageRenderBig = ({ image_url, node, processedImages }) => {
        return (
            <ImageTransform imagesources={image_url} renderer="srcSet" imagename="peoples.Image.tile_big" attr={{ alt: node.Name + ' - Redbrik', class: '' }} imagetransformresult={processedImages} id={node.id} />
        )
    }
    let catList = [{label: "All People", value:"all"}]
    if(allcategory.length > 0){
        allcategory.map((node, key) => {
            catList.push({label: node.Name, value:node.URL})
        })
    }
 
    return (
        <Container className="team-landing">
            {props.Select_Category_here == null &&
                <>
                    {screenSize > 767  ?
                        <div className="news-section">
                            <ul className="nav-tabs">
                                {/*<li className={`nav-link ${category === 'all' ? 'active' : ''}`} onClick={() => setCategory('all')}>
                                    All People
                                </li>*/}
                                {allcategory.map((node, key) => {
                                    return (
                                        <li className={`nav-link ${category === node.URL ? 'active' : ''}`} onClick={() => setCategory(node.URL)} data-filter={node.URL}>
                                            {node.Name}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        :
                        <div className="news-section-select">
                           <Select
                                name="people"
                                options={catList}
                                isSearchable={false}
                                classNamePrefix={"select-opt  people-category"}
                                defaultValue={category === "all" ? "All People" : category}
                                value={catList.find(
                                    obj => obj.value === category
                                )}
                                placeholder="All People"
                                onChange={e => {
                                    setCategory(e.value)
                                }}
                                styles={customStyles}
                                
                                />
                        </div>
                    }

                </>
            }
            {screenSize > 1025 &&
                <div className="about-page">

                    <Row className="team-row ">

                        {filteredTeam.map((node, key) => {
                            const image_url = node.Image.url

                            let processedImages = JSON.stringify({});
                            if (node?.imagetransforms?.Image_Transforms) {
                                processedImages = node.imagetransforms.Image_Transforms;
                            }

                            return (
                                <>
                                    <Col className="mx-0">
                                        <div className="team-member-tile">
                                            <div className="about-img img-zoom">
                                                <Link className={`${lettingsPropList.includes(node.Designation)===false?'pointer-events':''}`}  to={`${MEET_THE_TEAM_PAGE_URL.alias}/${node.URL}`}>
                                                    <ImageRenderOne processedImages={processedImages} image_url={image_url} node={node} />
                                                </Link>
                                                <div className="person-info">
                                                    <Link className={`${lettingsPropList.includes(node.Designation)===false?'pointer-events':''}`} to={`${MEET_THE_TEAM_PAGE_URL.alias}/${node.URL}`}><h2 className="h4">{node.Name}</h2></Link>
                                                    {node.Designation && <div className="designation">{node.Designation}</div>}
                                                    {node.Designation && lettingsPropList.includes(node.Designation) &&
                                                    <div className="member-link">
                                                        <MailForm name={node.Name} member_name={node.Name} email={node.Email} tag="teampage" formLabel="Person Contact"/>
                                                        <span>/</span>
                                                        <Link className={`${lettingsPropList.includes(node.Designation)===false?'pointer-events':''}`} to={`${MEET_THE_TEAM_PAGE_URL.alias}/${node.URL}`}>Bio</Link>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </>

                            )
                        })}




                    </Row>


                </div>
            }

            {(screenSize < 1025) &&
                <div className="about-page">
                    <Row className="team-row ">
                        {filteredTeam.map((node, key) => {
                            const image_url = node.Image.url

                            let processedImages = JSON.stringify({});
                            if (node?.imagetransforms?.Image_Transforms) {
                                processedImages = node.imagetransforms.Image_Transforms;
                            }

                            return (
                                <>
                                    <Col xs={6} sm={6}>
                                        <div className="team-member-tile">
                                            <div className="about-img ">
                                                <Link to={node.URL}>

                                                    <ImageRenderOne processedImages={processedImages} image_url={image_url} node={node} />
                                                </Link>
                                                <div className="person-info">
                                                    <Link to={node.URL}><h2 className="h4">{node.Name}</h2></Link>
                                                    {node.Designation && <div className="designation">{node.Designation}</div>}
                                                    {(node.Designation === "Director"||node.Designation==="Associate Director") &&
                                                    <div className="member-link">
                                                        <MailForm name={node.Name} email={node.Email} tag="teampage" formLabel="Person Contact"/>
                                                        <span>/</span>
                                                        <Link to={`${MEET_THE_TEAM_PAGE_URL.alias}/${node.URL}`}>Bio</Link>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </>
                            )
                        })}
                    </Row>
                </div>
            }
        </Container>
    )
}

export default TeamList