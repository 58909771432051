import React, { } from "react"
import { get } from 'lodash';
import { Link } from "@StarberryUtils"

import ModalTiny from "react-bootstrap/Modal"
import parse from 'html-react-parser';
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import GenerateLink from "../../common/site/generate-link"
import { sitename } from "@constants";
import "./Banner.scss"
import ReviewsCount from '../Reviews/review-count'
import bannerlogo from "../../../images/banner-logo.png"
import GoogleReview from "../../../images/google-reviews-white.svg"

// Header component
let slider = {};

const ValuationBanner = (props) => {

  const contents = get(props, 'Content_Block', []);
  const img = get(props, 'Image.url') 
  const [showModal, setShowModal] = React.useState(false);
  const [modalData, setModalData] = React.useState({});
  const openModal = () => {
    setShowModal(true);
  }
  const hideModal = () => {
    setShowModal(false);
  }

  const handleonClick = (e, cta) => {
    e.preventDefault();
    setShowModal(true);
    setModalData(cta)
  }

  let gatsbyImg = getImage(props?.Image?.url_sharp)

  let pagename = props?.Pagename;

  return (
    <section className="home-banner home-banner-links home-banner-valuation">
      <div className="banner-item">
      <div className="banner-img">
        {/* When using ggfx clitn component, banner image getting time to load, facing some jump issue. Using gatsby image to over come this */}
        <GatsbyImage image={gatsbyImg} alt={img.alternativeText || `${pagename} Banner - ${sitename} Estate Agents`} className="d-block" />
      </div>

      <div className="banner-content-alt d-lg-flex justify-content-center">
        <div className="">
          <div>

            {props.Title && (
              <h2>{props.Title}</h2>
            )}

            {props.Content &&
              <>{parse(props.Content)}</>
            }
            <div className="content-block">
              {contents.map((cnt, i) => {
                let ctas = get(cnt, 'CTA_Block', []);
                return (
                  <div className="content">
                    <h3><em>{cnt.Title} </em> {cnt.Bold_Title}</h3>
                    {cnt.Content &&
                      <div >
                        {parse(cnt.Content)}
                      </div>
                    }
                    {(ctas.length) && (
                      <div className="btn-list">
                        {ctas.map((cta, i) => {
                          if (cta?.Target === 'Popup') {
                            return (
                              <a href={cta.Cta_Link} onClick={(e) => handleonClick(e, cta)} className="btn btn-trans">{cta.Cta_Label}</a>
                            )
                          } else {

                            if (cta?.Cta_Link.match(/http|https/)) {
                              return (
                                <a href={cta.Cta_Link} target={cta?.Target === 'New_Tab' ? '_blank' : ''} className="btn btn-trans">{cta.Cta_Label}</a>
                              )
                            } else {
                              return (
                                <Link to={cta.Cta_Link} target={cta?.Target === 'New_Tab' ? '_blank' : ''} className="btn btn-trans">{cta.Cta_Label}</Link>
                              )
                            }

                          }
                        })}
                      </div>
                    )}
                  </div>
                )
              })}
            </div>

            {/*props.Show_Google_Review &&
              <div className="google-reviews">
                <picture>
                  <img
                    src={GoogleReview}
                    alt="reviews-img01"
                    className="google_review_logo"
                  />
                </picture>
                <ReviewsCount />

              </div>
            */ }
          </div>
        </div>
      </div>
      </div>
      <ModalTiny show={showModal} onHide={hideModal} className="book-a-viewing-modal iframe-modal">
        <ModalTiny.Header closeButton className="contact-close-btn">
          <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">{modalData?.Cta_Label}</h2></ModalTiny.Title>
        </ModalTiny.Header>
        <ModalTiny.Body className="event-section">
          <iframe src={modalData?.Cta_Link} width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </ModalTiny.Body>
      </ModalTiny>
    </section>

  )
}
export default ValuationBanner
