import React from "react";
// import { Link } from "@StarberryUtils"
// import { graphql } from 'gatsby'
// import SEO from "../components/seo"

import Banner from "./Home/HomeBanner/Banner" 
import HomeBanner from "./Home/HomeBanner/HomeBanner"
import BannerImage from "./Home/HomeBanner/BannerImage"
import ValuationBanner from "./Home/HomeBanner/ValuationBanner"
import Choice from "./Home/Choice/Choice"
import OurPackages from "./OurPackages/OurPackages"
import Video from "./Home/Choice/video"
import ImageAndVideoBlock from "./Home/ImageAndVideoBlock/ImageAndVideoBlock"
import ImageWithReview from "./Home/ImageAndVideoBlock/ImageWithReview"
import Stats from "./Home/Stats/Stats"
import OurServices from "./Home/OurServices/OurServices"
import NewsBlock from "./Home/NewsBlock/NewsBlock"
import Reviews from "./Home/Reviews/Reviews"
import Services from "./Home/Services/Services"
import ServicesOne from "./Home/Services/ServicesOne"
import Branches from "./Home/Branches/Branches"
import Contact from "./Home/ContactUs/Contact"
import PlainHeader from "./StaticPage/PlainHeader"
import CareerVideos from "./StaticPage/CareerVideos"
import SellingHeader from "./SellingPage/SellingBanner"
import Instagram from "./Instagram"
import TeamSlider from "./Home/Services/TeamSlider"
import StaticBanner from "./StaticPage/StaticBanner"
import StaticContent from "./StaticPage/StaticContent/StaticContent"
import LatestUpdate from "./StaticPage/LatestUpdate/LatestUpdate"
import StayUpdate from "./StaticPage/StayUpdate/StayUpdate"
import StaticBannerVideo from "./StaticPage/StaticBannerVideo"
import FAQ from "./FAQ/FAQ"
import SocialPromotion from "./StaticPage/SocialPromotion/Promotion"
import News from "./StaticPage/News/News"
import Team from "./Team/team-landing"
import TeamCategory from "./Team/team-landing-category"
import AreaGuides from "./AreaGuides/AreaGuides"
import AllAreaGuides from "./AreaGuides/AllAreaGuides"
import RedbrikRecommends from "./AreaGuides/RedbrikRecommends"
import Offices from "./Offices/Location"
import JobsList from "./JobsList/JobsList"
// import Statement from "./StaticPage/Statement/Statement";
import Statement from "./SellingPage/ShortStatement/ShortStatement";
import SellingProperty from "./SellingPage/SellingProperty/SellingProperty"

import VideoPromotion from "./SellingPage/VideoPromotion/VideoPromotion"
import ImagePromotion from "./SellingPage/ImagePromotion/ImagePromotion"
import ImageRightContent from "./SellingPage/ImageRightContent/ImageRightContent"
import SocialPromotionAnimated from "./SellingPage/SocialPromotion/SocialPromotion"

import GetInTouch from "./GetInTouch/GetInTouch"
import Valuation from "./Valuation/Valuation"
import Register from "./Register/Register"
import Careers from "./Careers/Careers"

import FullVideo from "./SellingPage/OurStatus/OurStatus"
import Offer from "./ProperyDetails/Offer/Offer"
import OfferBlack from "./ProperyDetails/Offer/OfferBlack"
import ContentBlock from "./ContentBlock/ContentBlock"
import AllReviews from "./ProperyDetails/CustomerReviews/Reviews"

import JoinUs from "./ProperyDetails/JoinUs/JoinUs";
import FeaturedProperties from "./ProperyDetails/SimilarProperties/FeaturedProperties"
import SoldProperties from "./ProperyDetails/SimilarProperties/SoldProperties"
import RentProperties from "./ProperyDetails/SimilarProperties/RentProperties"
import NewHomesProperties from "./ProperyDetails/SimilarProperties/NewHomesProperties"
import AllFeaturedProperties from "./ProperyDetails/SimilarProperties/AllFeaturedProperties" 
import GuidesBlock from "./Home/GuidesBlock/GuidesBlock"
import Accreditations from "./Home/Accreditations/Accreditations" 
import CharityPartners from "./Home/CharityPartners/CharityPartners" 
import PreviousEvents from "./Home/PreviousEvents/PreviousEvents"
import CurrentVacancies from "./Home/CurrentVacancies/CurrentVacancies" 

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss";
import "../scss/grunticon.scss";
import "../scss/custom.scss";
import "../scss/revamp.scss";
import RentalPackages from "./RentalPackages/RentalPacakges";
import CostLiving from "./CostLiving/CostLiving";
import ImageBlock from "./Home/ImageBlock/ImageBlock";
import ImageBlockSlide from "./Home/ImageBlock/ImageBlockSlide";
import DoubleImageBlock from "./Home/ImageBlock/DoubleImageBlock";
import Awards from "./Home/ImageBlock/Awards";


const Modules = (props) => {

    let sellingPropertyCount = 0;
    //console.log(props);
    return (
        <>
            {props.page.Banner_Image && props.page.Banner_Video_Link === null && props.page.Banner_Content_Alignment !== "Left" && props.page.Layout !== "Selling_Page" &&
                <StaticBanner {...props.page}/>
            }

            {props.page.Banner_Image && props.page.Banner_Video_Link === null && props.page.Banner_Content_Alignment === "Left" && props.page.Layout !== "Selling_Page" &&
                <StaticBannerVideo {...props.page}/>
            }

            {props.page.Banner_Image && props.page.Banner_Video_Link !== null && props.page.Layout !== "Selling_Page" &&
                <StaticBannerVideo {...props.page}/>
            }

            {props.page.Banner_Video_Link !== null && props.page.Layout === "Selling_Page" &&
                <SellingHeader {...props.page}/>
            }


            {
                props.modules && props.modules.map((module, i) => {
                    if (module?.Add_Rightside_Content && module.Add_Images_here)
                        sellingPropertyCount++;
                    return (
                        <div className="page-modules">
                            {module?.Banner_Image &&
                                <BannerImage Pagename={props?.page?.Pagename} pageId={props.page._id} imagetransforms={props.page.imagetransforms} {...module}/>
                            }
                            {module.__typename == "GLSTRAPI_ComponentRevampBannerValuation" &&
                                <ValuationBanner Pagename={props?.page?.Pagename} pageId={props.page._id} imagetransforms={props.page.imagetransforms} {...module}/>
                            }
                            {module.Slider_Image &&
                                <Banner Pagename={props?.page?.Pagename} pageId={props.page._id} imagetransforms={props.page.imagetransforms} {...module}/>
                            }
                            {module.__typename == "GLSTRAPI_ComponentRevampRevampBanner" && 
                              <HomeBanner Pagename={props?.page?.Pagename} pageId={props.page._id} imagetransforms={props.page.imagetransforms} {...module}/>
                            }

                            {module?.Header_Content &&
                                <PlainHeader Pagename={props?.page?.Pagename} page={props.page} {...module}/>
                            }
                            {props?.page?.Layout === "Static_Page" &&  module.Select_Collection === "Rental_Packages" &&
                                <div className="package-collections">
                                    <RentalPackages className={"Rental_Packages"} Pagename={props?.page?.Pagename}  Title={module.Title} bold_title={module?.bold_title} Page_module = {props.modules} />
                                </div>
                            }                            
                            {module.Select_Module === "accreditations"  &&
                                <Accreditations Pagename={props?.page?.Pagename}  bold_title={module?.bold_title} {...module}/>
                            }
                            {module.Select_Collection === "Charity_partners"  &&
                                <CharityPartners Pagename={props?.page?.Pagename} bold_title={module?.bold_title} {...module}/>
                            }
                             {module.Select_Collection === "Service_Block_Contents"  &&
                                <ImageBlockSlide Pagename={props?.page?.Pagename} bold_title={module?.bold_title} {...module}/>
                            }
                            {module.Select_Collection === "Previous_Events"  &&
                                <PreviousEvents Pagename={props?.page?.Pagename} bold_title={module?.bold_title} {...module}/>
                            }
                             {module.Select_Collection === "Current_vacancies"  &&
                                <CurrentVacancies Pagename={props?.page?.Pagename} bold_title={module?.bold_title} {...module}/>
                            }
                            {module.Intro_Title &&
                                <Choice {...module} Pagename={props?.page?.Pagename}  page={props?.page} />
                            }
                            {props?.page?.Layout === "Static_Page" && module?.Add_Static_Content &&
                                <StaticContent data={props.modules} banner={props.page.Banner_Image ? '' : 'no-banner'}/>
                            }

                            {module?.Video_Embed_URL && module.Video_Background_Image && module.Video_Style === "Right_Align" &&
                                <Video Pagename={props?.page?.Pagename} pageId={props.page._id} imagetransforms={props.page.imagetransforms} {...module}/>
                            }
                            {module.__typename == "GLSTRAPI_ComponentRevampImageContentBlock" && !module.Show_Review &&
                                <ImageAndVideoBlock Pagename={props?.page?.Pagename} pageId={props.page._id} imagetransforms={props.page.imagetransforms} {...module}/>
                            }
                            {module.__typename == "GLSTRAPI_ComponentRevampImageContentBlock" && module.Show_Review &&
                                <ImageWithReview Pagename={props?.page?.Pagename} pageId={props.page._id} imagetransforms={props.page.imagetransforms} {...module}/>
                            }
                            {module.__typename == "GLSTRAPI_ComponentRevampStatsModule" &&
                                <Stats Pagename={props?.page?.Pagename} pageId={props.page._id} imagetransforms={props.page.imagetransforms} {...module}/>
                            }
                            {module.__typename == "GLSTRAPI_ComponentRevampOurServices" &&
                                <OurServices Pagename={props?.page?.Pagename} pageId={props.page._id} {...module}/>
                            }
                            {module.__typename == "GLSTRAPI_ComponentRevampDoubleImageBlock" &&
                                <DoubleImageBlock Pagename={props?.page?.Pagename} pageId={props.page._id} imagetransforms={props.page.imagetransforms}  {...module}/>
                            }
                            {module.__typename == "GLSTRAPI_ComponentRevampAwards" &&
                                <Awards Pagename={props?.page?.Pagename} pageId={props.page._id} {...module}/>
                            }                        
                            {module.__typename == "GLSTRAPI_ComponentRevampServiceBlock" &&
                                <ImageBlock Pagename={props?.page?.Pagename} pageId={props.page._id} imagetransforms={props.page.imagetransforms} {...module}/>
                            }
                             {module.__typename == "GLSTRAPI_ComponentRevampTeamSlider" && 
                                <div className="office-details">
                                     <TeamSlider Pagename={props?.page?.Pagename} team={module.peoples} {...module}/>
                                </div>
                            }
                            {module.__typename == "GLSTRAPI_ComponentRevampFaq" &&
                                <FAQ Pagename={props?.page?.Pagename} pageId={props.page._id} {...module}/>
                            } 
                            {module.__typename == "GLSTRAPI_ComponentRevampContentBlock" &&
                                <ContentBlock Pagename={props?.page?.Pagename} pageId={props.page._id} {...module}/>
                            } 

                            {module?.Video_Background_Image && module.Video_Style === "Full_Width" &&
                                <FullVideo Pagename={props?.page?.Pagename} {...module} pagename={props.path}/>
                            }

                            {module?.Service_Tile && props.page.Pagename === "Home" &&
                                <Services Pagename={props?.page?.Pagename} pageId={props.page._id} imagetransforms={props.page.imagetransforms} {...module} class={i === 1 ? 'service-top' : ''}/>
                            }

                            {module?.Service_Tile && props.page.Pagename !== "Home" &&
                                <ServicesOne Pagename={props?.page?.Pagename} pageId={props.page._id} imagetransforms={props.page.imagetransforms} {...module} index={i} class={i === 1 ? 'service-top' : ''}/>
                            }

                            {module?.Select_Module === "Google_Reviews_Slider" &&
                                <Reviews Pagename={props?.page?.Pagename} pageId={props.page._id} imagetransforms={props.page.imagetransforms} />
                            }

                            {module?.Select_Module === "Register_with_Redbrik" &&
                                <JoinUs Pagename={props?.page?.Pagename} />
                            }

                            {module?.Select_Module === "Instagram_Widget" &&
                                <Instagram Pagename={props?.page?.Pagename} />
                            }

                            {module?.Featured_Property_Type &&
                                <FeaturedProperties Pagename={props?.page?.Pagename} {...module}/>
                            }
                            {module.Select_Module === "All_Feature_Properties" &&
                                <AllFeaturedProperties Pagename={props?.page?.Pagename} {...module}/>
                            }
                             {module.Select_Module === "Guide_Modue" &&
                                <GuidesBlock Pagename={props?.page?.Pagename} {...module}/>
                            }
                            {module.Select_Module === "Recently_Sold_Properties" &&
                                <SoldProperties Pagename={props?.page?.Pagename} {...module}/>
                            }
                            {module.Select_Module === "Rent_Properties" &&
                                <RentProperties Pagename={props?.page?.Pagename} {...module}/>
                            }
                            {module.Select_Module === "New_Homes_Properties" &&
                                <NewHomesProperties Pagename={props?.page?.Pagename} {...module}/>
                            }
                            {module.Select_Module === "News_Module" &&
                                <NewsBlock Pagename={props?.page?.Pagename} {...module}/>
                            }

                            {module?.Get_in_Touch_Content && module.Get_in_Touch_Background_Image &&
                                <Contact Pagename={props?.page?.Pagename} pageId={props.page._id} imagetransforms={props.page.imagetransforms} {...module} />
                            }

                            {module?.Select_Form === "Contact" &&
                                <GetInTouch Pagename={props?.page?.Pagename} pageId={props.page._id} imagetransforms={props.page.imagetransforms} {...module}/>
                            }

                            {module?.Select_Form === "Valuation" &&
                                <Valuation Pagename={props?.page?.Pagename} {...module}/>
                            }

                            {module?.Select_Form === "Register" &&
                                <Register Pagename={props?.page?.Pagename} {...module}/>
                            }

                            {module?.Select_Form === "Careers" &&
                                <Careers Pagename={props?.page?.Pagename} {...module}/>
                            }

                            {module?.Single_Block_Content && module.Single_Block_Image &&
                                <Branches Pagename={props?.page?.Pagename} pageId={props.page._id} imagetransforms={props.page.imagetransforms} {...module} />
                            }

                            {module?.Select_Collection === "Peoples" &&
                                <Team Pagename={props?.page?.Pagename} {...props.page}/>
                            }

                            {module.Select_Category_here &&
                                <TeamCategory Pagename={props?.page?.Pagename} {...module} />
                            }

                            {module?.Add_New_Video &&
                                <CareerVideos Pagename={props?.page?.Pagename} {...module}/>
                            }

                            {module?.Show_All_Jobs === true &&
                                <JobsList Pagename={props?.page?.Pagename} {...module}/>
                            }

                            {module?.Add_Services &&
                                <Statement Pagename={props?.page?.Pagename} {...module}/>
                            }

                            {module?.Select_Collection === "Area_Guides" &&
                                <AreaGuides Pagename={props?.page?.Pagename} />
                            }

                            {module?.Select_Collection === "News" &&
                                <News Pagename={props?.page?.Pagename} />
                            }

                            {module?.Select_Collection === "Offices" &&
                                <Offices Pagename={props?.page?.Pagename} />
                            }

                            {module?.Select_Collection === "Reviews" &&
                                <AllReviews Pagename={props?.page?.Pagename} />
                            }

                            {module?.Select_Collection === "Magazines" &&
                                <div className="magazine-main-block">
                                    <LatestUpdate Pagename={props?.page?.Pagename} />                                    
                                </div>
                            }

                            {module?.Add_Plan && module.Plans_Module_Style === "White_Background" &&
                                <Offer Pagename={props?.page?.Pagename} {...module}/>
                            }

                            {module?.Add_Plan && module.Plans_Module_Style === "Black_Background" &&
                                <OfferBlack Pagename={props?.page?.Pagename} {...module}/>
                            }

                            {module?.Add_Image_here && module.Animation_Type == "Social_Promotion" &&
                                <SocialPromotionAnimated Pagename={props?.page?.Pagename}{...module}/>
                            }

                            {/* Commented for now as per client request - Maddy https://www.bugherd.com/projects/246865/tasks/659 */}
                            {(module?.Add_Image_here && module.Animation_Type == "Street_Two_Videos" && !["/sell/"].includes(props.path)) &&
                                <VideoPromotion Pagename={props?.page?.Pagename} {...module}/>
                            }

                            {module?.Add_Image_here && module.Animation_Type == "Image_With_Content" &&
                                <ImagePromotion Pagename={props?.page?.Pagename} {...module}/>
                            }

                            {module?.Add_Rightside_Content && module.Add_Images_here &&
                                <SellingProperty Pagename={props?.page?.Pagename} {...module} count={sellingPropertyCount} />
                            }

                            {module?.Content && module.Add_Image_here && module?.Ctas &&
                                <ImageRightContent Pagename={props?.page?.Pagename} {...module}/>
                            }
                            {module.Select_Collection === "Guides" &&
                               <CostLiving  {...module} />
                            }
                            {module.Select_Module === "All_Area_Guide" &&
                               <AllAreaGuides Pagename={props?.page?.Pagename} {...module} />
                            }
                            {module.Select_Module === "Redbrik_Recommends" &&
                               <RedbrikRecommends Pagename={props?.page?.Pagename} {...module} />
                            }

                            {/*module.Top_Title &&
                                <CostLiving Title={module.Top_Title} Description={module.Top_Description} Guide_Items={module.Guides}/>
                           */}
                        </div>
                    )
                })
            }
        </>
    )
}

export default Modules
