import React,{useState, useEffect} from "react";
import { Link } from "@StarberryUtils"
import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby";
import ShowMoreText from 'react-show-more-text';
import parse from 'html-react-parser';
import ReactTimeAgo from 'react-time-ago'
import Count from '../../Home/Reviews/review-count'

import ScrollAnimation from "react-animate-on-scroll";
import reviewsimg01 from "../../../images/reviews-img01.png"
import reviewsimg02 from "../../../images/reviews-img01.png"
import reviewsimg03 from "../../../images/reviews-img01.png"
import client01 from "../../../images/client01.png"
// import Slider from "react-slick";
import "./Reviews.scss"

const postsPerPage = 21;
let arrayForHoldingPosts = [];

const Reviews = () => {

    const [postsToShow, setPostsToShow] = useState([]);
    const [next, setNext] = useState(21);
  


    const data = useStaticQuery(graphql`
    query {
      glstrapi {
        
        reviews(publicationState: LIVE, sort: "Date:DESC") {
          id
          Name
          Date
          Publish
          Rating
          Review_Content
          Image {
            url
          }
        }
  
        globalModule {
          Google_Reviews_Content
        }
  
      }
    }
  `);
  
  const reviews = data.glstrapi.reviews;
  // const module = data.glstrapi.globalModule;

  
  const loopWithSlice = (start, end) => {
    const slicedPosts = reviews.slice(start, end);
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    //console.log("review",arrayForHoldingPosts)
    setPostsToShow(arrayForHoldingPosts);
  };

  const handleShowMorePosts = () => {
    loopWithSlice(next, next + postsPerPage);
    setNext(next + postsPerPage);
  };

  useEffect(() => {
    


    loopWithSlice(0, postsPerPage);
  }, []);


    return (
        <React.Fragment>
            <section className="reviews-section all-customer-reviews">
                <Container>
                    <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                        {/* <div className="main-heading">
                            <h3>Customer reviews</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vel efficitur tellus, laoreet finibus orci.
                                Nam eget consectetur mi orbi sodales sapien.</p>
                        </div> */}
                        {/* <h2>Reviews</h2> */}
                        <Row className="reviews-row">
                        {postsToShow.map((item, i) => (
                            <Col xs={12} sm={6} lg={4} className="one-col">
                            <div className="slider-wrapper slides">
                                    <div className="home-img">
                                        <picture>
                                            <img src={item.Image.url} alt={item.Name} />
                                        </picture>
                                        <div className="home-content">
                                            <h4>{item.Name}</h4>
                                            <span className="time"><ReactTimeAgo date={item.Date} locale="en-US"/></span>
                                        </div>
                                    </div>
                                    {item.Review_Content &&
                                    <p>
                                    <ShowMoreText
                                    /* Default options */
                                    lines={4}
                                    more='More'
                                    less='Less'
                                    className='google-review-content-css desc'
                                    anchorClass='btn-more review-more'
                                    expanded={false}
                                    keepNewLines={false}
                                    >
                    
                                        {parse(item.Review_Content)}
                                    </ShowMoreText>
                                    </p>
                                    }
     
                                    {item.Rating === "One" &&
                                    <ul className="rating-icons">
                                    <li>
                                        <i className="icon-star"></i>
                                    </li>
                                    </ul>
                                    }
                                    {item.Rating === "Two" &&
                                    <ul className="rating-icons">
                                    <li>
                                        <i className="icon-star"></i>
                                    </li>
                                    <li>
                                        <i className="icon-star"></i>
                                    </li>
                                    </ul>
                                    }
                                    {item.Rating === "Three" &&
                                    <ul className="rating-icons">
                                    <li>
                                        <i className="icon-star"></i>
                                    </li>
                                    <li>
                                        <i className="icon-star"></i>
                                    </li>
                                    <li>
                                        <i className="icon-star"></i>
                                    </li>
                                    </ul>
                                    }
                                    {item.Rating === "Four" &&
                                    <ul className="rating-icons">
                                    <li>
                                        <i className="icon-star"></i>
                                    </li>
                                    <li>
                                        <i className="icon-star"></i>
                                    </li>
                                    <li>
                                        <i className="icon-star"></i>
                                    </li>
                                    <li>
                                        <i className="icon-star"></i>
                                    </li>
                                    </ul>
                                    }
                                    {item.Rating === "Five" &&
                                    <ul className="rating-icons">
                                    <li>
                                        <i className="icon-star"></i>
                                    </li>
                                    <li>
                                        <i className="icon-star"></i>
                                    </li>
                                    <li>
                                        <i className="icon-star"></i>
                                    </li>
                                    <li>
                                        <i className="icon-star"></i>
                                    </li>
                                    <li>
                                        <i className="icon-star"></i>
                                    </li>
                                    </ul>
                                    }
                                </div>
                            </Col>
                            )
                            )}
                        </Row>
                    </ScrollAnimation>
                    
                    {arrayForHoldingPosts.length !== reviews.length ?
                        <div className="pagination-btn">
                        <button className="btn d-block d-md-inline-block btn-pagination" onClick={handleShowMorePosts}>Load more</button>
                        </div> : ''
                    }
                </Container>
            </section>
        </React.Fragment >
    )
}

export default Reviews
