import React from "react";
import { Link } from "@StarberryUtils"
import parse from 'html-react-parser';
import {Row, Col, Container } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import { useStaticQuery, graphql } from "gatsby";
import { isMobileOnly  } from 'react-device-detect';
import Slider from "react-slick";

import logoBlack from "../../images/redbrik-new-logo.svg";

import ImageTransform from "../common/ggfx-client/module/components/image-transform";

import "../ProperyDetails/Offer/Offer.scss";

function CareerVideos(props) {

    const data = useStaticQuery(graphql`
        query {
        glstrapi {
            careers(where:{Publish:true}) {
                id
                Title
                URL
                Tile_Image {
                url
                alternativeText
                }
                Office
                Salary
                imagetransforms
            }
        }
        }
    `);

    const settings = {
        dots: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <React.Fragment>
            <section className="our-offer black all-jobs" id="jobs-list">
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                    {props.Jobs_List_Info_Title &&
                    <Container className="head">
                        <Row>
                            <Col sm={12} lg={12}>
                                <div className="content">
                                    <div className="info-title">
                                        {props.Jobs_List_Info_Title}
                                    </div>
                                    <div className="intro">
                                        {parse(props.Jobs_List_Content)}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    }
                    <Container className="jobs-list">
                        {(!isMobileOnly) &&
                        <Row>
                        {data.glstrapi.careers.map((node, key) => {
                            const image_url = node.Tile_Image.url

                            let processedImages = JSON.stringify({});
                            if (node?.imagetransforms?.Tile_Image_Transforms) {
                                processedImages = node.imagetransforms.Tile_Image_Transforms;
                            }
                            return (
                            <Col sm={12} lg={4}>
                                <Link to={node.URL}>
                                <div className="job">
                                    <ImageTransform imagesources={image_url} renderer="srcSet" imagename="careers.Tile_Image.tile_1" attr={{ alt: node.Title+ ' - Redbrik', class:'' }} imagetransformresult={processedImages} id={node.id}/>
                                    <div className="details">
                                        <h4>{node.Title}</h4>
                                        {/*<div className="salary">
                                            {node.Office} . {node.Salary}
                                        </div>*/}
                                    </div>
                                </div>
                                </Link>
                            </Col>
                            )
                        })}
                        </Row>
                        }
                        {(isMobileOnly) &&

                            <Slider {...settings}>
                          {data.glstrapi.careers.map((node, key) => {
                              const image_url = node.Tile_Image.url

                              let processedImages = JSON.stringify({});
                              if (node?.imagetransforms?.Tile_Image_Transforms) {
                                  processedImages = node.imagetransforms.Tile_Image_Transforms;
                              }
                              return (
                              <div>
                                  <Link to={node.URL}>
                                  <div className="job">
                                      <ImageTransform imagesources={image_url} renderer="srcSet" imagename="careers.Tile_Image.tile_1" attr={{ alt: node.Title+ ' - Redbrik', class:'' }} imagetransformresult={processedImages} id={node.id}/>
                                      <div className="details">
                                          <h4 className="mb-0">{node.Title}</h4>
                                          {/* <div className="salary">
                                              {node.Office} . {node.Salary}
                                          </div> */}
                                      </div>
                                  </div>
                                  </Link>
                              </div>
                              )
                          })}
                          </Slider>
                        }
                    </Container>
                </ScrollAnimation>
            </section>
        </React.Fragment>
    )
}

export default CareerVideos;
